import React, { useState, useEffect } from "react";
import { Alert, Button, Classes, Dialog, FormGroup, InputGroup, Intent, Spinner, Tag } from "@blueprintjs/core";
import { AmplifyConfigType, RecordEnumType, RecordType, RECORD_ENUM_TYPE_LIST } from "common/types";
import { formatLocalTime } from "common/utils";
import Select, { ISelect } from "comps/Select";
import Swal from "sweetalert2";
import { API } from "aws-amplify";
import { useSelector } from "react-redux";
import { AppStateProps } from "reducer/appReducer";

const Domain:React.FC = () => {
  const [loadingData, setLoadingData] = useState(true);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [zoneList, setZoneList] = useState<ISelect []>([]);
  const [curDomainId, setCurDomainId] = useState("");
  const [recordList, setRecordList] = useState<RecordType []>([]);
  const [curDomainName, setCurDoamianName] = useState("");
  const [currentRecord, setCurrentRecord] = useState<RecordType>();

  const [newRecordName, setNewRecordName] = useState("");
  const [eventName, setEventName] = useState("");
  const [recordType, setRecordType] = useState<string>(RecordEnumType.CNAME);
  const [recordValue, setRecordValue] = useState("");

  const [eventEmptyError, setEventEmptyError] = useState(false);
  const [nameEmptyError, setNameEmptyError] = useState(false);
  const [recordValueEmptyError, setRecordValueEmptyError] = useState(false);

  const amplifyConfig: AmplifyConfigType = useSelector(
    (state: AppStateProps) => state.amplifyConfig
  );

  const alertProps = {
    canEscapeKeyCancel: false,
    canOutsideClickCancel: false,
    isLoading: false,
    isOpen: false,
    isOpenError: false,
    willLoad: false,
  };

  const getDomainMetaList = async () => {
    const initData = {
      headers: { "content-type": "application/json" },
      response: true,
    };
    setLoadingData(true);
    API
      .get(amplifyConfig.API.endpoints[0].name, "/domain/meta", initData)
      .then(response => {
        const metaData = response.data;
        console.info("metaData:", metaData);
        const tmpOptionList:ISelect [] = [];
        if (metaData && metaData.zones && metaData.zones.length > 0) {
          metaData.zones.forEach((element:any, index:number) => {
            if (index === 0) {
              setCurDoamianName(element.domain);
              setCurDomainId(element.id);
            }
            tmpOptionList.push({
              name: element.domain,
              value: element.id
            });
          });
        }
        setZoneList(tmpOptionList);
      })
      .catch(error => {
        setLoadingData(false);
        console.error(error);
        Swal.fire(
          `${error.message}`,
          `${error?.response?.data?.message}`,
          undefined
        );
      });
  };

  const getRecordList = async () => {
    const initData = {
      headers: { "content-type": "application/json" },
      response: true,
    };
    setLoadingData(true);
    API
      .get(amplifyConfig.API.endpoints[0].name, "/domain/" + curDomainId, initData)
      .then(response => {
        setLoadingData(false);
        const recordData = response.data;
        if ((typeof recordData === "string")) {
          Swal.fire("Ops..", recordData);
        } else {
          setRecordList(recordData);
        }
      })
      .catch(error => {
        setLoadingData(false);
        console.error(error);
        Swal.fire(
          `${error.message}`,
          `${error?.response?.data?.message}`,
          undefined
        );
      });
  };

  const buildRecordValue = (type:string, value: string) => {
    if (type === RecordEnumType.TXT) {
      return `"${value}"`;
    }
    return value;
  };

  const createRecord = async () => {
    if (!eventName.trim()) {
      setEventEmptyError(true);
      return;
    }
    if (!newRecordName.trim()) {
      setNameEmptyError(true);
      return;
    }
    if (!recordValue.trim()) {
      setRecordValueEmptyError(true);
      return;
    }
    const initData = {
      body: {
        eventName,
        recordType,
        recordValue: buildRecordValue(recordType, recordValue),
        "content-type": "application/json",
      },
      headers: { "content-type": "application/json" },
      response: true,
    };
    setLoadingCreate(true);
    API.put(amplifyConfig.API.endpoints[0].name, `/domain/${curDomainId}/${newRecordName}.${curDomainName}`, initData).then((res) => {
      if (res.data) {
        setNewRecordName("");
        setRecordValue("");
        setNameEmptyError(false);
        setLoadingCreate(false);
        setOpenModal(false);
        getRecordList();
      }
    }).catch((error) => {
      setLoadingCreate(false);
      console.info(error);
      Swal.fire(
        `${error.message}`,
        `${error?.response?.data?.message}`,
        undefined
      );
    });
  };

  const confirmDeleteRecord = async () => {
    const initData = {
      body: {
        recordType: currentRecord?.type,
        recordValue: currentRecord?.value
      },
      headers: { "content-type": "application/json" },
      response: true,
    };
    setLoadingDelete(true);
    API.del(amplifyConfig.API.endpoints[0].name, `/domain/${curDomainId}/${currentRecord?.record}`, initData).then((res) => {
      setLoadingDelete(false);
      if (res.data) {
        setOpenDelete(false);
        getRecordList();
      }
    }).catch((error) => {
      setOpenDelete(false);
      setLoadingDelete(false);
      console.info(error);
      Swal.fire(
        `${error.message}`,
        `${error?.response?.data?.message}`,
        undefined
      );
    });
  };

  const showOpenDelete = (item: RecordType) => {
    setCurrentRecord(item);
    setOpenDelete(true);
  };

  useEffect(() => {
    if (curDomainId) {
      getRecordList();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curDomainId]);

  useEffect(() => {
    getDomainMetaList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container">
      <div className="flex space-between">
        <div className="table-title">Record List</div>
        <div>
          <div className="flex align-middle">
            <div className="mr-10">
              <Select changeItem={(element) => {
                setCurDomainId(element.value);
                setCurDoamianName(element.name);
              }} optionList={zoneList} title={curDomainName}/>
            </div>
            <div>
              <Button loading={loadingData} onClick={() => { getRecordList(); }} className="m-tb-10 mr-10" intent={Intent.NONE} icon="refresh"></Button>
              <Button onClick={() => { setOpenModal(true); }} className="m-tb-10" intent={Intent.PRIMARY} icon="add">Create Record</Button>
            </div>
          </div>

        </div>
      </div>
      <div>
        {loadingData
          ? <Spinner/>
          : (
            <table width={"100%"} className="bp4-html-table modifier">
              <thead>
                <tr>
                  <th>Record</th>
                  <th>Type</th>
                  <th>Value</th>
                  <th>Expired On</th>
                  <th>Created</th>
                  <th>Created By</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {recordList.map((element, index) => {
                  return (
                    <tr key={index}>
                      <td>{element.record}</td>
                      <td>{element.type}</td>
                      <td>{element.value}</td>
                      <td>{element.expiredTimeInMills ? formatLocalTime(element.expiredTimeInMills) : "-"}</td>
                      <td>{element.createdTimeInMills ? formatLocalTime(element.createdTimeInMills) : "-"}</td>
                      <td>{element.createdBy || "-"}</td>
                      <td><Button onClick={() => {
                        showOpenDelete(element);
                      }} small icon="trash" intent={Intent.DANGER}></Button></td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}

      </div>
      <Dialog title="Create Event" isCloseButtonShown autoFocus canEscapeKeyClose canOutsideClickClose enforceFocus shouldReturnFocusOnClose usePortal isOpen={openModal} onClose={() => {
        setOpenModal(false);
      }}>
        <div className={Classes.DIALOG_BODY}>
          <FormGroup
            helperText={eventEmptyError ? "Please in put event name" : ""}
            label="Event Name"
            labelFor="text-input"
            labelInfo="(required)"
            intent={eventEmptyError ? Intent.DANGER : Intent.NONE}
          >
            <InputGroup id="text-input" disabled={loadingCreate} value={eventName} onChange={(event) => {
              setEventEmptyError(false);
              setEventName(event.target.value);
            }} placeholder="workshop-event" />
          </FormGroup>

          <FormGroup
            helperText={nameEmptyError ? "Please in put sub-domain name" : ""}
            label="Sub Domain Name"
            labelFor="domain-input"
            labelInfo="(required)"
            intent={nameEmptyError ? Intent.DANGER : Intent.NONE}
          >
            <InputGroup id="domain-input" disabled={loadingCreate} value={newRecordName} onChange={(event) => {
              setNameEmptyError(false);
              setNewRecordName(event.target.value);
            }} placeholder="sub-domain" rightElement={<Tag minimal={true}>{`.${curDomainName}`}</Tag>} />
          </FormGroup>

          <FormGroup
            label="Record Type"
            labelInfo="(required)"
          >
            <Select changeItem={(element) => {
              setRecordType(element.value);
            }} optionList={RECORD_ENUM_TYPE_LIST} title={recordType}/>
          </FormGroup>

          <FormGroup
            helperText={recordValueEmptyError ? "Please in put record value" : recordType === RecordEnumType.NS ? "Please use comma to split multiple value" : ""}
            label="Record Value"
            labelFor="record-input"
            labelInfo="(required)"
            intent={recordValueEmptyError ? Intent.DANGER : Intent.NONE}
          >
            <InputGroup id="record-input" disabled={loadingCreate} value={recordValue} onChange={(event) => {
              setRecordValueEmptyError(false);
              setRecordValue(event.target.value);
            }} placeholder={recordType === RecordEnumType.NS ? "xxx.awsdns-35.com.,xxx.awsdns-34.co.uk." : "record value"} />
          </FormGroup>

        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={() => {
              setOpenModal(false);
            }}>Close</Button>
            <Button
              loading={loadingCreate}
              onClick={() => {
                createRecord();
              }}
              intent="primary"
              icon="saved"
            >
                Create
            </Button>
          </div>
        </div>
      </Dialog>
      <Alert
        {...alertProps}
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
        icon="trash"
        intent={Intent.DANGER}
        isOpen={openDelete}
        loading={loadingDelete}
        onCancel={() => {
          setOpenDelete(false);
        }}
        onConfirm={() => {
          confirmDeleteRecord();
        }}
      >
        <p>
           Are you sure you want to delete <b>{currentRecord?.record}</b>?
        </p>
      </Alert>
    </div>
  );
};

export default Domain;
