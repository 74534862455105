import React, { useState, useEffect } from "react";
import { Button, Classes, Dialog, FormGroup, InputGroup, Intent, Spinner } from "@blueprintjs/core";
import { AmplifyConfigType, EventType } from "common/types";
import { formatLocalTime } from "common/utils";
import { DatePicker } from "@blueprintjs/datetime";
import Swal from "sweetalert2";
import { API } from "aws-amplify";
import { AppStateProps } from "reducer/appReducer";
import { useSelector } from "react-redux";

const Admin:React.FC = () => {
  const [loadingData, setLoadingData] = useState(true);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [eventList, setEventList] = useState<EventType []>([]);
  const [newEventName, setNewEventName] = useState("");
  const [nameEmptyError, setNameEmptyError] = useState(false);
  const [newEventExpireDate, setNewEventExpireDate] = useState(new Date());
  const amplifyConfig: AmplifyConfigType = useSelector(
    (state: AppStateProps) => state.amplifyConfig
  );

  const getEventList = async () => {
    const initData = {
      headers: { "content-type": "application/json" }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    };
    setLoadingData(true);
    API
      .get(amplifyConfig.API.endpoints[0].name, "/event", initData)
      .then(response => {
        setLoadingData(false);
        const eventData = response.data;
        if ((typeof eventData === "string")) {
          Swal.fire("Ops..", eventData);
        } else {
          setEventList(eventData);
        }
      })
      .catch(error => {
        setLoadingData(false);
        console.error(error);
        Swal.fire(
          `${error.message}`,
          `${error?.response?.data?.message}`,
          undefined
        );
      });
  };

  const createEvent = async () => {
    if (!newEventName.trim()) {
      setNameEmptyError(true);
      return;
    }
    const initData = {
      body: {
        expiredTimeInMills: newEventExpireDate.getTime(),
        "content-type": "application/json"
      },
      headers: { "content-type": "application/json" },
      response: true,
    };
    setLoadingCreate(true);
    API.put(amplifyConfig.API.endpoints[0].name, "/event/" + newEventName, initData).then((res) => {
      if (res.data) {
        setNewEventExpireDate(new Date());
        setNewEventName("");
        setNameEmptyError(false);
        setLoadingCreate(false);
        setOpenModal(false);
        getEventList();
      }
    }).catch((error) => {
      setLoadingCreate(false);
      console.info(error);
      Swal.fire(
        `${error.message}`,
        `${error?.response?.data?.message}`,
        undefined
      );
    });
  };

  useEffect(() => {
    getEventList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container">
      <div className="flex space-between">
        <div className="table-title">Event List</div>
        <div>
          <Button loading={loadingData} onClick={() => { getEventList(); }} className="m-tb-10 mr-10" intent={Intent.NONE} icon="refresh"></Button>
          <Button onClick={() => { setOpenModal(true); }} className="m-tb-10" intent={Intent.PRIMARY} icon="add">Create Event</Button></div>
      </div>
      <div>
        {loadingData
          ? <Spinner/>
          : (
            <table width={"100%"} className="bp4-html-table modifier">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Expired On</th>
                  <th>Created</th>
                  <th>Created By</th>
                  {/* <th>Actions</th> */}
                </tr>
              </thead>
              <tbody>
                {eventList.map((element, index) => {
                  return (
                    <tr key={index}>
                      <td>{element.id}</td>
                      <td>{element.eventName}</td>
                      <td>{element.expiredTimeInMills ? formatLocalTime(element.expiredTimeInMills) : "-"}</td>
                      <td>{element.createdTimeInMills ? formatLocalTime(element.createdTimeInMills) : "-"}</td>
                      <td>{element.createdBy || "-"}</td>
                      {/* <td><Button small icon="trash" intent={Intent.DANGER}></Button></td> */}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}

      </div>
      <Dialog title="Create Event" isCloseButtonShown autoFocus canEscapeKeyClose canOutsideClickClose enforceFocus shouldReturnFocusOnClose usePortal isOpen={openModal} onClose={() => {
        setOpenModal(false);
      }}>
        <div className={Classes.DIALOG_BODY}>
          <FormGroup
            helperText={nameEmptyError ? "Please in put event name" : ""}
            label="Event Name"
            labelFor="text-input"
            labelInfo="(required)"
            intent={nameEmptyError ? Intent.DANGER : Intent.NONE}
          >
            <InputGroup id="text-input" disabled={loadingCreate} value={newEventName} onChange={(event) => {
              setNameEmptyError(false);
              setNewEventName(event.target.value);
            }} placeholder="workshop-event" />
          </FormGroup>
          <FormGroup
            // helperText="Please in put event name"
            label="Expired Date"
            labelFor="data-input"
            labelInfo="(required)"
          >
            <div className="flex">
              <div>
                <DatePicker minDate={new Date()} value={newEventExpireDate} onChange={(date) => {
                  setNewEventExpireDate(date);
                }} />
              </div>
              <div className="create-expire-on">
                <div className="title">Expired On:</div>
                <div><b>{formatLocalTime(newEventExpireDate.getTime())}</b></div>
              </div>
            </div>
          </FormGroup>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={() => {
              setOpenModal(false);
            }}>Close</Button>
            <Button
              loading={loadingCreate}
              onClick={() => {
                createEvent();
              }}
              intent="primary"
              icon="saved"
            >
                Create
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default Admin;
