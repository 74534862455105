import React from "react";
import {
  Alignment,
  AnchorButton,
  Classes,
  Navbar,
  NavbarGroup,
  NavbarHeading,
  NavbarDivider,
  Button,
  Menu,
} from "@blueprintjs/core";
import { useNavigate } from "react-router-dom";
import { MenuItem2, Popover2 } from "@blueprintjs/popover2";
import { AuthEventData, CognitoUserAmplify } from "@aws-amplify/ui";

export interface NavigationProps {
  signOut?: ((data?: AuthEventData | undefined) => void) | undefined;
  user?: CognitoUserAmplify | undefined;
}

export const Navigation: React.FC<NavigationProps> = (props: NavigationProps) => {
  const navigate = useNavigate();
  const { signOut, user } = props;
  console.info("user:", user);
  return (
    <Navbar className={Classes.DARK}>
      <NavbarGroup align={Alignment.LEFT}>
        <NavbarHeading><b>Dealer</b></NavbarHeading>
        <NavbarDivider />
        <Button onClick={() => { navigate("/"); }} icon="home">Home</Button>
        <NavbarDivider />
        <AnchorButton
          href="https://gitlab.aws.dev/aws-gcr-solutions/incubator/dealer/-/blob/main/README.md"
          text="Docs"
          target="_blank"
          minimal
          rightIcon="share"
        />
        <AnchorButton
          href="https://gitlab.aws.dev/aws-gcr-solutions/incubator/dealer"
          text="Github"
          target="_blank"
          minimal
          rightIcon="code"
        />
      </NavbarGroup>
      <NavbarGroup align={Alignment.RIGHT}>
        <Popover2 content={<Menu >
          <MenuItem2 icon="log-out" text="Sign Out" onClick={() => {
            signOut && signOut();
          }}/>
        </Menu>} placement={"bottom-start"}>
          <Button icon="user" rightIcon="caret-down" text={user?.attributes?.email} />
        </Popover2>
      </NavbarGroup>
    </Navbar>
  );
};
