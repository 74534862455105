export interface AmplifyConfigType {
  aws_project_region: string;
  Auth: {
    region: string;
    userPoolId: string;
    userPoolWebClientId: string;
    authenticationFlowType: string;
  };
  API: {
    endpoints: [
      {
        name: string;
        endpoint: string;
        custom_header: any;
      }
    ];
  };
}
export interface EventType {
  eventName: string;
  id: string;
  expiredTimeInMills: number;
  createdTimeInMills: number;
  createdBy: string;
  lastModifiedTimeInMills: number;
  lastModifiedBy: string;
}

export interface RecordType {
  record: string;
  type: string;
  value: string;
  id: string;
  expiredTimeInMills: number;
  createdTimeInMills: number;
  createdBy: string;
}

export enum RecordEnumType {
  "CNAME" = "CNAME",
  "TXT" = "TXT",
  "NS" = "NS",
}

export const RECORD_ENUM_TYPE_LIST = [
  {
    name: "CNAME",
    value: RecordEnumType.CNAME,
  },
  {
    name: "TXT",
    value: RecordEnumType.TXT,
  },
  {
    name: "NS",
    value: RecordEnumType.NS,
  },
];
