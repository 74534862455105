import React, { useEffect, useState } from "react";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import "@blueprintjs/select/lib/css/blueprint-select.css";
import "@blueprintjs/popover2/lib/css/blueprint-popover2.css";

import "./App.css";
import {
  HashRouter,
  Routes,
  Route
} from "react-router-dom";

import Home from "./pages/Home";
import Admin from "./pages/Admin";
import { Navigation } from "./comps/Navigation";
import Domain from "pages/Domain";

import { Amplify, Auth } from "aws-amplify";
import {
  Authenticator,
  Flex,
  View,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import Axios from "axios";
import { useDispatch } from "react-redux";
import { ActionType } from "reducer/appReducer";
import { AmplifyConfigType } from "common/types";

const App:React.FC = () => {
  const [loadingConfig, setLoadingConfig] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    setLoadingConfig(true);
    Axios.get("/aws-exports.json").then((res) => {
      const configData: AmplifyConfigType = res.data;
      const tokenHeader = async () => { return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }; };
      configData.API.endpoints[0].custom_header = tokenHeader;
      Amplify.configure(configData);
      // const currentUser = Auth.userAttributes;
      // console.info("currentUser:", currentUser);
      dispatch({
        type: ActionType.UPDATE_AMPLIFY_CONFIG,
        amplifyConfig: res.data,
      });
      setLoadingConfig(false);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loadingConfig) {
    return (
      <div className="pd-20 text-center">
        Loading...
      </div>
    );
  }

  return (
    <Authenticator>
      {({ signOut, user }) => (
        <Flex
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          alignContent="flex-start"
          wrap="nowrap"
          gap="1rem"
          textAlign="center"
        >
          <View width="100%">
            <div>
              <header>
                <HashRouter>
                  <Navigation user={user} signOut={signOut} />
                  {user?.getSignInUserSession()?.getIdToken().payload?.["cognito:groups"]?.[0] === "admin"
                    ? (

                      <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/admin" element={<Admin />}/>
                        <Route path="/domain" element={<Domain />}/>
                      </Routes>

                    )
                    : (
                      <Routes>
                        <Route path="/" element={<Domain />} />
                      </Routes>
                    )}
                </HashRouter>
              </header>
            </div>
          </View>
        </Flex>
      )}
    </Authenticator>
  );
};

export default App;
