import React from "react";
import { Select2, ItemRenderer } from "@blueprintjs/select";
import { Button } from "@blueprintjs/core";
import { MenuItem2 } from "@blueprintjs/popover2";
export interface ISelect {
  /** Title of Select. */
  name: string;
  /** Value of Select. */
  value: string;
}

interface CustomSelectProps {
  title: string;
  optionList: ISelect []
  changeItem: (item: ISelect) => void;
}

const CustomSelect = Select2.ofType<ISelect>();

const renderSelect:ItemRenderer<ISelect> = (
  item:ISelect,
  { handleClick, modifiers }:any
) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }
  // const text = `${item.name}`;
  return (
    <MenuItem2
      active={modifiers.active}
      disabled={modifiers.disabled}
      label={item.name.toString()}
      key={item.value}
      onClick={handleClick}
    />
  );
};

const Select:React.FC<CustomSelectProps> = (props: CustomSelectProps) => {
  const { title, optionList, changeItem } = props;
  return (
    <div>
      <CustomSelect
        filterable={false}
        items={optionList}
        // itemPredicate={Films.filterFilm}
        itemRenderer={renderSelect}
        // noResults={<MenuItem disabled={true} text="No results." />}
        onItemSelect={(event) => {
          changeItem(event);
        }}
      >
        <Button className="select-button" text={title} rightIcon="caret-down" />
      </CustomSelect>
    </div>
  );
};

export default Select;
