import React from "react";
import { Button } from "@blueprintjs/core";
import { useNavigate } from "react-router-dom";

const Home:React.FC = () => {
  const navigate = useNavigate();
  return (
    <div className="app-header">
      <h1>Welcome to Dealer</h1>
      <div className="flex">
        <div className="margin-10">
          <Button onClick={() => {
            navigate("/admin");
          }} icon="timeline-events" large>
            Event List
          </Button>
        </div>
        <div className="margin-10">
          <Button onClick={() => {
            navigate("/domain");
          }} icon="app-header" large>
            Domain List
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Home;
