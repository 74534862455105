import { AmplifyConfigType } from "common/types";

export enum ActionType {
  UPDATE_AMPLIFY_CONFIG,
}

export interface AppStateProps {
  amplifyConfig: any;
}

const initialState: AppStateProps = {
  amplifyConfig: {},
};

export type Action = {
  type: ActionType.UPDATE_AMPLIFY_CONFIG;
  amplifyConfig: AmplifyConfigType;
};

const appReducer = (state = initialState, action: Action): AppStateProps => {
  switch (action.type) {
    case ActionType.UPDATE_AMPLIFY_CONFIG:
      return { ...state, amplifyConfig: action.amplifyConfig };
    default:
      return state;
  }
};

export default appReducer;
